import React, { useContext } from 'react';
//Import Images
import WomanImg from '../img/home/yana3.png';

//Import Links
import {Link} from 'react-router-dom';

//Import motion
import { motion } from 'framer-motion';

//Import transitions
import { transition1 } from '../transitions';

//Import Cursor Context
import { CursorContext } from '../context/CursorContext';

const Home = () => {
  const { mouseEnterHandler, mouseLeaveHandler } = useContext(CursorContext)
  return (
  <motion.section 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={transition1}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
      className='section'
      >
        <div className='container mx-auto h-full relative'>
          {/** Text and Images Wrapper */}
          <div className='flex flex-col justify-center'>
            {/** Text */}
             <motion.div 
              initial={{ opacity: 0, y: '-50%' }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: '-50%' }}
              transition={transition1}            
              className='w-full pt-36 pb-14 lg:pt-0 lg:pb-0 lg:w-auto z-10 lg:absolute 
              flex flex-col justify-center items-center lg:items-start'>
              <h1 className='h1 lg:text-7xl'>
                Actress <br/> & Model
              </h1>
              <p className='text-[26px] lg:text-[36px] font-primary mb-4 lg:mb-12'>
                Miami - Atlanta - Los Angeles
              </p>
              <Link to={'/contact'} className='btn mb-[30px]'>Contact me</Link>
            </motion.div>
            {/** Image */}
            <motion.div 
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              transition={transition1}              
              className='flex justify-end lg:max-h-max'>
              <div className='relative lg:-right-40 overflow-hidden'>
                <motion.img
                  whileHover={{ scale: 1.1 }} 
                  transition={transition1}
                  src={WomanImg} 
                  alt=''
                  />
              </div>
            </motion.div> 
          </div>
        </div>
  </motion.section>
  );
};

export default Home;
 