import { React } from 'react';
import { AnimatePresence } from 'framer-motion';

//import pages
import Home from '../pages/Home';
import About from '../pages/About';
import Portfolio from '../pages/Portfolio';
import Gallery from '../pages/Gallery';
import Contact from '../pages/Contact';


//import routes route and useLocations hook
import {Routes, Route, useLocation} from 'react-router-dom';


const AnimRoutes = () => {
  const location = useLocation();  
  return (
    <AnimatePresence initial={true} mode='wait'>
      <Routes key={location.pathname} location={location}>
        <Route path='/' element={<Home/>} />
        <Route path='/about' element={<About/>} />
        <Route path='/portfolio' element={<Portfolio/>} />
        <Route path='/gallery' element={<Gallery/>} />
        <Route path='/contact' element={<Contact/>} />
      </Routes>
    </AnimatePresence>

  );
};

export default AnimRoutes;
