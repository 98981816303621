import React, {useContext} from 'react';
import WomanImg from '../img/about/yana_about.jpg';
import { Link } from 'react-router-dom';

//Import motion
import { motion } from 'framer-motion';

//Import transitions
import { transition1 } from '../transitions';

//Import CursorContext
import { CursorContext } from '../context/CursorContext';

const About = () => {

  const {mouseEnterHandler, mouseLeaveHandler} = useContext(CursorContext);

  return (
  <motion.section 
      initial={{ opacity: 0, y: '100%' }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: '100%' }}
      transition={transition1}    
      className='section'
       >
    <div className='container mx-auto h-full relative'>
      {/** Text and Image Wrapper */}
      <div className='flex flex-col lg:flex-row h-full items-center justify-center 
        gap-x-24 text-center lg:text-left lg:pt-16'>
        <div className='flex-1 lg:pt-32 lg:max-h-max order-2 lg-order-none overflow-hidden'>
          <img src={WomanImg} alt=''/>
        </div>
        <motion.div 
          initial={{ opacity: 0, y: '-80%' }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: '-80%' }}
          transition={transition1}   
          onMouseEnter={mouseEnterHandler}
          onMouseLeave={mouseLeaveHandler} 
          className='flex-1 pt-36 pb-14 lg:pt-48 lg:w-auto z-10 flex flex-col justify-center 
          items-center lg:items-start '>
          <h1 className='h1'>
          About me
          </h1>
          <p className='mb-12 max-w-sm items-start justify-start lg:mt-10'>
          Hi there. Welcome to my page where the worlds of acting and modeling come alive! I'm thrilled to have you here as we embark on an exciting journey through my artistic endeavors. As an actress, aspiring stunt woman, and commercial/fitness model, I bring a captivating blend of talent, versatility, and sheer determination to every project.
          <br/>
          <br/>
          With a background in gymnastics, various martial arts and a touch of experience in fight choreography, I've learned to infuse my performances with an extra dose of intensity and authenticity. Whether I'm playing a dynamic character on screen or striking a pose in front of the camera, I bring a unique energy that sets me apart.
          <br/>
          <br/>
          So join me as we explore the captivating worlds of acting and modeling, where I invite you to participate and witness my passion, versatility, and unwavering commitment to the art. Together, let's create something truly extraordinary!
         </p>
          <Link to={'/portfolio'} className='btn'>View my work</Link> 
        </motion.div>
      </div>
    </div>
  </motion.section>
  )
};

export default About;
