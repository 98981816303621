import React, { useContext, Fragment } from 'react';

//Import images
import Image1 from '../img/portfolio/1.png';
import Image2 from '../img/portfolio/2.png';
import Image3 from '../img/portfolio/3.png';
import Image4 from '../img/portfolio/4.png';
import Image5 from '../img/portfolio/5.png';
import Image6 from '../img/portfolio/6.png';
import Image7 from '../img/portfolio/7.png';
import Image8 from '../img/portfolio/8.png';
import Image9 from '../img/portfolio/9.png';


//Import Links
import { Link } from 'react-router-dom';
 
//Import motion
import { motion } from 'framer-motion';

//Import transitions
import { transition1 } from '../transitions';

import { CursorContext } from '../context/CursorContext';



const Portfolio = () => {

  const { mouseEnterHandler, mouseLeaveHandler } = useContext(CursorContext);

  return (
    <motion.section 
      initial={{ opacity: 0, y: '100%' }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: '100%' }}
      transition={transition1}  
      className='section'>
      <div className='container mx-auto h-full relative'>
        <div className='flex flex-col lg:flex-row h-full items-center justify-start gap-x-24 
        text-center lg:text-left pt-24 lg:pt-36 pb-8'>
          <motion.div 
            initial={{ opacity: 0, y: '-80%' }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: '-80%' }}
            transition={transition1}   
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}               
            className='flex flex-col lg:items-start'>
            <h1 className='h1'>Portfolio</h1>
            <p className='mb-12 max-w-sm'>
              <br/>
              My true passion lies in acting, where I get to fully immerse myself in the art of storytelling. From portraying complex emotions to embracing diverse roles, I find joy in breathing life into characters and connecting with audiences on a profound level. Modeling, on the other hand, allows me to express my creativity through fashion and visual storytelling.
              <br/>
              <br/>
              Throughout my journey, I've honed my skills, and my discipline has been instrumental in shaping my craft. I'm a firm believer in hard work, pushing boundaries, and never settling for anything less than my best. This unwavering determination has fueled my growth and opened doors to exciting opportunities.
            </p>
          <Link to={'/contact'} className='btn mb-[30px] mx-auto lg:mx-0'>Contact me</Link> 
          </motion.div>
          {/** Image grid */}
          <div 
          onMouseEnter={mouseEnterHandler}
          onMouseLeave={mouseLeaveHandler}
          className='grid grid-cols-2 lg:grid-cols-3 lg:gap-2 lg:mt-24'> 
            <div className='max-w-[187px] lg:max-w-[264px] h-[250px] lg:h-[320px] bg-accent overflow-hidden'>
              <img 
              className='object-cove h-full hover:scale-110  
              transition-all duration-500'               
              src={Image1} 
              alt=''
              />
            </div>
            <div className='max-w-[187px] lg:max-w-[264px] h-[250px] lg:h-[320px] bg-accent overflow-hidden'>
              <img 
              className='object-cove h-full hover:scale-110 
              transition-all duration-500'              
              src={Image2} 
              alt=''
              />
            </div>
            <div className='max-w-[187px] lg:max-w-[264px] h-[250px] lg:h-[320px] bg-accent overflow-hidden'>
              <img 
              className='object-cove h-full  hover:scale-110 
              transition-all duration-500'              
              src={Image3} 
              alt=''
              />
            </div>
            <div className='max-w-[187px] lg:max-w-[264px] h-[250px] lg:h-[320px] bg-accent overflow-hidden'>
              <img 
              className='object-cove h-full hover:scale-110 
              transition-all duration-500'              
              src={Image4} 
              alt=''
              />              
            </div>  
            <div className='max-w-[187px] lg:max-w-[264px] h-[250px] lg:h-[320px] bg-accent overflow-hidden'>
              <img 
              className='object-cove h-full hover:scale-110 
              transition-all duration-500'              
              src={Image5} 
              alt=''
              />              
            </div>   
            <div className='max-w-[187px] lg:max-w-[264px] h-[250px] lg:h-[320px] bg-accent overflow-hidden'>
              <img 
              className='object-cove h-full hover:scale-110 
              transition-all duration-500'              
              src={Image6} 
              alt=''
              />              
            </div>   
            <div className='max-w-[187px] lg:max-w-[264px] h-[250px] lg:h-[320px] bg-accent overflow-hidden'>
              <img 
              className='object-cove h-full hover:scale-110 
              transition-all duration-500'              
              src={Image7} 
              alt=''
              />              
            </div>   
            <div className='max-w-[187px] lg:max-w-[264px] h-[250px] lg:h-[320px] bg-accent overflow-hidden'>
              <img 
              className='object-cove h-full  hover:scale-110 
              transition-all duration-500'              
              src={Image8} 
              alt=''
              />              
            </div>   
            <div className='max-w-[187px] lg:max-w-[264px] h-[250px] lg:h-[320px] bg-accent overflow-hidden'>
              <img 
              className='object-cove h-full hover:scale-110 
              transition-all duration-500'              
              src={Image9} 
              alt=''
              />              
            </div>                                                                                                 
          </div>
         
        </div>
      </div>    
    </motion.section>
  )
};

export default Portfolio;
 