import React from 'react';
import '../Gallery.css';

//Import images
import Image1 from '../img/portfolio/1.png';
import Image2 from '../img/portfolio/2.png';
import Image3 from '../img/portfolio/3.png';
import Image4 from '../img/portfolio/4.png';
import Image5 from '../img/portfolio/5.png';
import Image6 from '../img/portfolio/6.png';
import Image7 from '../img/portfolio/7.png';
import Image8 from '../img/portfolio/8.png';
import Image9 from '../img/portfolio/9.png';

const images = [
  {
    src: Image1,
    alt: Image1,
  },
  {
    src: Image2,
    alt: Image2,
  },
  {
    src: Image3,
    alt: Image3,
  },
  {
    src: Image4,
    alt: Image4,
  },
  {
    src: Image5,
    alt: Image5,
  },
  {
    src: Image6,
    alt: Image6,
  },  
  {
    src: Image7,
    alt: Image7,
  },  
  {
    src: Image8,
    alt: Image8,
  },  
  {
    src: Image9,
    alt: Image9,
  },        
];

function Gallery() {
  return (
    <div className="flex align-top lg:mt-20 lg:pt-64 gallery">
      {images.map((image, index) => (
        <img
          key={index}
          className="gallery__item"
          src={image.src}
          alt={image.alt}
        />
      ))}
    </div>
  );
}

export default Gallery;
 