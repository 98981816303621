import React, {useContext} from 'react';
//import icons
import {
  ImFacebook,
  ImTwitter,
  ImPinterest,
  ImInstagram,
  ImYoutube,
} from 'react-icons/im';

import { CursorContext } from '../context/CursorContext';

const Socials = () => {

  const { mouseEnterHandler, mouseLeaveHandler } = useContext(CursorContext);

  return (
    <div onMouseEnter={mouseEnterHandler} onMouseLeave={mouseLeaveHandler} className ="hidden xl:flex ml-24">
      <ul className="flex gap-x-4 text-[#666666]">
        <li>
          <a href='https://instagram.com/yfit47' target='_blank' rel='noreferrer'>
            <ImInstagram />
          </a> 
        </li>
        <li>
          <a href='https://facebook.com' target='_blank' rel='noreferrer'>
            <ImFacebook />
          </a> 
        </li>
        {/** 
        <li>
          <a href='https://twitter' target='_blank' rel='noreferrer'>
            <ImTwitter />
          </a> 
        </li>
        <li>
          <a href='https://pinterest.com' target='_blank' rel='noreferrer'>
            <ImPinterest />
          </a> 
        </li>

        <li>
          <a href='https://youtube.com' target='_blank' rel='noreferrer'>
            <ImYoutube />
          </a> 
        </li>
        */}                                
      </ul>
    </div>
  )};

export default Socials;
