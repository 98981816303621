import React, { useContext } from 'react';
import GalleryComp from './GalleryComp';

//Import Links
import { Link } from 'react-router-dom';
 
//Import motion
import { motion } from 'framer-motion';

//Import transitions
import { transition1 } from '../transitions';

import { CursorContext } from '../context/CursorContext';



const Portfolio = () => {

  const { mouseEnterHandler, mouseLeaveHandler } = useContext(CursorContext);

  return (
    <motion.section 
      initial={{ opacity: 0, y: '100%' }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: '100%' }}
      transition={transition1}  
      className='section'>
      <div className='container mx-auto h-full relative'>
        <div className='flex flex-col lg:flex-row h-full items-center justify-start gap-x-24 
        text-center lg:text-left pt-24 lg:pt-36 pb-8'>
          <motion.div 
            initial={{ opacity: 0, y: '-80%' }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: '-80%' }}
            transition={transition1}   
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}               
            className='flex flex-col lg:items-start'>
            <h1 className='h1'>Portfolio</h1>
            <p className='mb-12 max-w-sm'>
              <br/>
              My true passion lies in acting, where I get to fully immerse myself in the art of storytelling. From portraying complex emotions to embracing diverse roles, I find joy in breathing life into characters and connecting with audiences on a profound level. Modeling, on the other hand, allows me to express my creativity through fashion and visual storytelling.
              <br/>
              <br/>
              Throughout my journey, I've honed my skills, and my discipline has been instrumental in shaping my craft. I'm a firm believer in hard work, pushing boundaries, and never settling for anything less than my best. This unwavering determination has fueled my growth and opened doors to exciting opportunities.
            </p>
          <Link to={'/contact'} className='btn mb-[30px] mx-auto lg:mx-0'>Contact me</Link> 
          </motion.div>
          {/** Image grid */}
          <motion.div 
            initial={{ opacity: 0, y: '-80%' }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: '-80%' }}
            transition={transition1}   
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}               
            className='flex flex-col lg:items-start'>
            <GalleryComp />  
          </motion.div>

        </div>
      </div>    
    </motion.section>
  )
};

export default Portfolio;
 