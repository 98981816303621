import React, { useState, useEffect, createContext }  from 'react';

//Create context
export const CursorContext = createContext();

  const CursorProvider = ({children}) => {
  //Cursor position state
  const [cursorPos, setCursorPos] = useState({
    y: 0,
    x: 0,
  });


  const mobileViewPortIsActive = window.innerWidth < 768;

  //Cursor Background State
  const [cursorBG, setCursorBG] = useState('default');

  useEffect(() => {
    if(!mobileViewPortIsActive){
      const move = (e) => {
        setCursorPos({
          x: e.clientX, 
          y: e.clientY,
        });   
      };
      window.addEventListener('mousemove', move);
      //remove event
      return () => {
        window.removeEventListener('mousemove', move);
      };      
    } else {
      setCursorBG('none');
    }
  });

  //console.log(cursorPos);

  //Cursor Variants
  const cursorVariants = {
    default: {
      x: cursorPos.x - 16,
      y: cursorPos.y - 16,
      backgroundColor: '#0e1112',
    },
    text: {
      width: '150px',
      height: '150px',
      x: cursorPos.x -72,
      y: cursorPos.y -72, 
      backgroundColor: '#fff',
      mixBlendMode: 'difference',
    },
    none: {
      width: 0,
      height: 0,
      backgroundColor: 'rgba(255, 255, 255, 1)', 
    }
  }

  //mouse enter handler
  const mouseEnterHandler = () => {
    setCursorBG('text')
  }

  //mouse exit handler
  const mouseLeaveHandler = () => {
    setCursorBG('default')
  }

  return (
    <CursorContext.Provider value={{ cursorVariants, cursorBG, mouseEnterHandler, mouseLeaveHandler }}>
      {children}
    </CursorContext.Provider>
  );
};

export default CursorProvider;
