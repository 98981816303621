import React, { useContext, useState } from 'react';

//Import images
import WomanImg from '../img/contact/contact_yana.jpg';

//Import motion
import { motion } from 'framer-motion';

//Import transitions
import { transition1 } from '../transitions';

import { CursorContext } from '../context/CursorContext';

import emailjs from 'emailjs-com';

import { useForm } from 'react-hook-form';
 
import ReCAPTCHA from 'react-google-recaptcha';
 
const Contact = () => {

  const { mouseEnterHandler, mouseLeaveHandler } = useContext(CursorContext);
  const [statusMessage, setStatusMessage] = useState("");
  const form = useForm();
  const { register, reset } = form;
  const [verified, setVerifed] = useState(false);

  //Recaptcha functionality
  function onChange(value){
    console.log("Captcha Value: ", value);
    setVerifed(true);
  }

  function sendEmail(e){
     e.preventDefault();
    // Old public ID: GpBLmOiJRccmH5YGK
     emailjs.sendForm('yanaWebsite','yana_template', e.target,'7izory-tRaHlWol00')
      .then(res=>{
        console.log(res);
        setStatusMessage('Your message has been sent successfully =)');
        reset();
      })
      .catch(err=>{
        console.log(err);
        setStatusMessage('There was an error trying to send your message :(');
      });
  }

  return (
  <motion.section 
      initial={{ opacity: 0, y: '100%' }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: '100%' }}
      transition={transition1}      
      className='section bg-[#fff] '>
    <div className='container mx-auto h-full'>
      <div className='flex flex-col lg:flex-row items-center justify-start pt-36 gap-x-8 
      text-center lg:text-left'>
        {/** Background */}
        <motion.div 
        initial={{ opacity: 0, y: '100%' }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: '100%' }}
        transition={transition1}           
        className='hidden lg:flex bg-[#efefef] absolute bottom-0 left-0 right-0 top-72 -z-10'>
        </motion.div>
        {/** Text and form */}
        <div 
        onMouseEnter={mouseEnterHandler}
        onMouseLeave={mouseLeaveHandler}
        className='lg:flex-1 lg:pt-32 lg:px-4'>
          <h1 className='h1'>Contact me</h1>
          <p className='mb-12'>I would love to get sugestions from you.</p>
          <form onSubmit={sendEmail} className='flex flex-col gap-y-4'>
            <div className='flex gap-x-10'>
              <input
              className='outline-none border-b border-b-primary h-[60px] bg-transparent 
              font-secondary w-full pl-3 placeholder:text-[#757879]'
              type='text' name='user_name' placeholder='Your name' {...register('user_name')} />

              <input
              className='outline-none border-b border-b-primary h-[60px] bg-transparent 
              font-secondary w-full pl-3 placeholder:text-[#757879]'
              type='text' name='user_email' placeholder='Your email' {...register('user_email')} />

            </div> 
              <input
              className='outline-none border-b border-b-primary h-[60px] bg-transparent 
              font-secondary w-full pl-3 placeholder:text-[#757879]'
              type='text ' 
              placeholder='Your message' 
              name='user_message'
              {...register('user_message')}
              />
              <ReCAPTCHA sitekey='6LdL7rEpAAAAAD0IOTQJmGe9s5TiYlMdO0y12ROu' onChange={onChange}/>
              <button 
              disabled={!verified}
              className={!verified ? 'btn btn-disabled mb-[30px] mx-auto lg:mx-0 self-start' : 'btn mb-[30px] mx-auto lg:mx-0 self-start'} 
              >
                Send it 
              </button>
          </form>
          <div className='flex flex-col items-center justify-center bg-[#dc9a00fa]'><p>{statusMessage}</p></div>
        </div>
        {/** Image */}
        <motion.div 
          onMouseEnter={mouseEnterHandler}
          onMouseLeave={mouseLeaveHandler}
          initial={{ opacity: 0, y: '100%' }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: '100%' }}
          transition={{ transition: transition1, duration: 1.5}}         
          className='lg:flex-1'>
          <img src={WomanImg} alt=''/>
        </motion.div>
      </div>
    </div>
  </motion.section>
  )
};

export default Contact;
