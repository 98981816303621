import React, {useContext} from 'react';

//import components
import Socials from './Socials';
import Logo from '../img/header/yana_logo.png';
import MobileNav from './MobileNav';

import { Link } from 'react-router-dom';
import { CursorContext } from '../context/CursorContext';

const Header = () => {

  const {mouseEnterHandler, mouseLeaveHandler } = useContext(CursorContext);

  return (
    <header className='fixed w-full px-[30px] lg:px-[100px] z-30 h-[100px] lg:h-[140px] flex items-center'>
      <div className='flex flex-col lg:flex-row lx:items-center w-full justify-between'>
        {/* Logo*/}
        <Link 
        onMouseEnter={mouseEnterHandler}
        onMouseLeave={mouseLeaveHandler} 
        to={'/'} 
        className='max-w-[450px]'>
            <img src={Logo} alt="logo"/>
        </Link>

        {/* Nav initially hidden shown on desktop mode*/}
        <nav 
        onMouseEnter={mouseEnterHandler}
        onMouseLeave={mouseLeaveHandler} 
        className='hidden lg:flex gap-x-12 font-semibold pt-[35px]'>
          <Link 
            to={'/'}
            className='text-[#666666] hover:text-primary transition'>Home
          </Link>
          <Link 
            to={'/about'}
            className='text-[#666666] hover:text-primary transition'>About
          </Link>
          <Link  
            to={'/portfolio'}
            className='text-[#666666] hover:text-primary transition'>Portfolio
          </Link>
          <Link 
            to={'/contact'} 
            className='text-[#666666] hover:text-primary transition'>Contact
          </Link>                    
        </nav> 
        
      </div>
      {/** Socials */}
      <Socials />

      {/** Mobile Nav */}
      <MobileNav />

    </header>  
  );
};
 
export default Header; 
